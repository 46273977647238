/*
  This file contains global css styles to be included with ever page
*/

@import "include.scss";

// Global classes and styles

.heading {
  display: block;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 15px;
}

.text {
  color: $c-grey;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

.text-emphasize {
  font-weight: bold;
}

.text-black {
  color: $c-black;
}

.text-white {
  color: $c-white;
}

.text-red {
  color: #f00;
}

.text-highlight {
  color: $c-h-pri;
}

.text-highlight-2 {
  color: $c-h-sec;
}

.text-indent {
  padding-left: 10px;
}

p {
  @extend .text;

  font-size: 16px;
  line-height: 20px;
}

h2 {
  @extend .text;

  font-size: 18px;
  line-height: 22px;
}

h1 {
  @extend .text;

  font-size: 24px;
  line-height: 28px;
}

.parallax {
  padding: 50px 0;
  background-attachment: fixed;
  background-color: $c-pri;
  background-position: center;
  background-repeat: no-repeat;

  @media #{$retina} {
    background-color: $c-pri;
    background-image: none !important;
  }
}

.parallax-image {
  @extend .parallax;

  background-size: cover;
}

// Core parts

html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
}

#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Page layout

$top-height: 90px;
$bottom-height: 80px;

#top {
  display: block;
  height: $top-height;
  max-height: $top-height;
  overflow: hidden;
  width: 100%;
}

#center {
  background-color: rgba(255, 255, 255, 0.5);
  display: block;
  margin: 0 auto;
  max-width: $media-large;
  min-height: calc(100% - #{$top-height});
  overflow: auto;
  width: 100%;
}

#popup-box {
  display: block;
  margin: 0 auto;
  max-width: $media-large;
  top: 0;
  width: 100%;
}

.popup {
  background-color: rgba($c-pri, 0.4);
  border-bottom: 1px solid $c-black;
  display: block;
  padding: 10px 25px;

  &:last-child {
    border-bottom: none;
  }

  > .title {
    display: block;
    text-align: center;
  }

  > .body {
    margin: 10px 0;
  }

  > .buttons {
    text-align: center;

    > button {
      padding: 5px 10px;
    }
  }
}

// Header styles

#header {
  background-color: $c-white;
  border-bottom: 1px solid darken($c-white, 10%);
  height: calc(#{$top-height} - 1px);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  > .wrapper-button {
    display: inline-block;
    height: $top-height - 10px;
    padding: 5px;
    vertical-align: top;
    width: $top-height - 10px;
    z-index: 101;

    > .menu-icon {
      @include menu-icon($top-height - 10px, $color: $c-pri, $radius: 20px);
    }
  }

  > .wrapper-logo {
    display: inline-block;
    height: $top-height;
    position: relative;
    width: calc(100% - #{$top-height});
    z-index: 101;

    > a {
      display: inline-block;
      height: $top-height;
      width: 100%;

      > .logo {
        @include logo($top-height, $paw-color: $c-pri, $text-color-pri: $c-pri, $text-color-sec: $c-black);
        width: 100%;
      }
    }
  }

  > .wrapper-nav {
    display: none;
    height: $top-height;
    line-height: $top-height;
    text-align: right;
    vertical-align: top;
    width: calc(100% - 300px);

    > .nav-links {
      display: inline-block;
      height: $top-height;
      padding: 0 10px;
      width: calc(100% - 20px);

      > a {
        display: inline-block;
        font-size: 21px;
        height: $top-height;
        margin: 0 10px;
        text-decoration: underline;
      }
    }
  }

  @media (min-width: $media-large) {
    > .wrapper-button {
      display: none;
    }

    > .wrapper-logo {
      width: 300px;
    }

    > .wrapper-nav {
      display: inline-block;
    }
  }
}

#header-push {
  display: block;
  height: $top-height;
}

// Footer styles

#footer {
  background-color: $c-pri;
  box-shadow: 0 -3px 3px 1px rgba(50, 50, 50, 0.4);
  display: block;
  padding: 10px 0;
  position: relative;
  z-index: 52;

  > .footer-contact {
    padding: 0 25px;
    text-align: left;

    > .footer-contact-entry {
      color: $c-black;
      display: block;
      margin: 0 auto;
      padding: 10px 0;
      max-width: 242px;

      > svg {
        width: 24px;
        height: 24px;
      }

      > span {
        display: inline-block;
        font-size: 18px;
        line-height: 24px;
        margin-left: 4%;
        vertical-align: top;
      }
    }
  }

  > .footer-links {
    padding: 2% 10%;
    margin: 0 auto;
    max-width: $media-medium;

    > a {
      color: $c-black;
      display: inline-block;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      text-decoration: none;
      vertical-align: top;
      width: calc(100% / 3);
    }
  }
}

// Navigation styles

#nav {
  background-color: $c-pri;
  display: inline-block;
  height: calc(100% - #{$top-height});
  opacity: 0;
  overflow-y: auto;
  right: -100%;
  position: fixed;
  top: $top-height;
  transition: right 0.3s, opacity 0.3s;
  width: 100%;
  z-index: 99;

  &.active {
    opacity: 1;
    right: 0;
  }

  > .nav-links > a {
    color: $c-black;
    display: block;
    padding: 25px 10%;
  }
}

.nav-links > a {
  color: $c-black;
  font-family: "Alfa Slab One", cursive;
  font-size: 24px;
  text-decoration: none;
}

// Page styles

#about {
  @include background-yellow-tiles();

  box-shadow: 0 3px 3px 1px rgba(50, 50, 50, 0.4);
  overflow: auto;
  position: relative;
  z-index: 49;

  #introduction {
    padding: 2% 5%;
    margin: 0 auto;
    max-width: $media-medium;
  }

  #showcase {
    position: relative;

    .slideshow-item {
      > div {
        background-size: cover;
        background-position: center;
        height: 400px;
      }

      @media (max-width: $media-small) {
        > div {
          height: 300px;
        }
      }
    }

    .indicators {
      margin: 0;
      position: absolute;
      bottom: 10px;
      margin-left: 50%;
      transform: translateX(-50%);

      > div {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

#contact {
  @include background-yellow-tiles();

  text-align: center;
  z-index: 51;

  #location {
    display: block;
    padding: 0 0 15px 0;
    text-align: center;
    width: 100%;

    > p {
      background-color: rgba($c-black, 0.5);
      border-radius: 5px;
      margin-left: auto;
      margin-right: auto;
      max-width: 650px;
      padding: 5px;
      text-align: left;

      > span {
        text-decoration: underline;
      }
    }

    iframe {
      border: 5px solid $c-white;
      border-radius: 2px;
      max-width: $media-small;
      width: calc(100% - 10px);
    }
  }

  #office-hours {
    background-color: rgba($c-black, 0.5);
    border-radius: 5px;
    color: $c-white;
    display: block;
    font-size: 16px;
    min-height: 150px;
    padding: 5px;
    vertical-align: top;

    > span {
      display: inline-block;
      margin: 10px 6px;
      width: calc((#{$media-large} - 10px) / 6 - 12px);

      > span {
        display: block;
      }

      > span:first-child {
        color: $c-pri;
      }
    }
  }

  #connect {
    background-color: rgba($c-black, 0.5);
    border-radius: 5px;
    display: block;
    height: 150px;
    padding: 5px;
    margin: 5px auto;
    max-width: 400px;
    vertical-align: top;

    > h2 {
      text-align: center;
    }

    > a {
      color: $c-white;
      display: inline-block;
      text-align: center;
      text-decoration: none;
      width: 50%;

      .icon {
        color: $c-white;
        padding: 3%;
        font-size: 28px;
      }

      > span {
        display: block;
      }
    }
  }
}

#services {
  $element-height: 40px;

  @include background-yellow-tiles();

  box-shadow: 0 0 6px 2px rgba(50, 50, 50, 0.4);
  position: relative;
  z-index: 51;
  overflow: auto;
  padding: 5%;

  .service-element {
    background-color: $c-white;
    border-radius: 8px;
    box-shadow: 0 0 5px 2px rgba(50, 50, 50, 0.3);
    line-height: $element-height;
    margin: $element-height/2 auto;
    max-width: $media-small;

    &:hover {
      cursor: pointer;

      > .service-head {
        > .service-cross {
          color: $c-h-pri;
        }

        > .service-title {
          color: $c-h-pri;
        }
      }
    }

    &.expanded {
      > .service-head > .service-cross {
        transform: rotate(45deg);
      }

      > .service-body {
        display: block;
      }
    }

    > .service-head {
      display: block;
      width: 90%;
      height: $element-height;

      > .service-cross {
        height: $element-height * 0.8;
        padding: $element-height * 0.1;
        transition: transform 0.2s, color 0.2s;
        width: $element-height * 0.8;
      }

      > .service-title {
        display: inline-block;
        transition: color 0.2s;
        vertical-align: top;
        width: calc(100% - #{$element-height});
      }
    }

    > .service-body {
      display: none;

      .service-img {
        border: 10px solid $c-pri;
        display: block;
        margin: 3%;
        width: calc(94% - 20px);
      }

      .service-description {
        @extend .text;

        display: block;
        padding: 2% 5%;
      }
    }
  }
}

#links {
  a {
    color: $c-black;
    text-decoration: underline;
  }

  > .tierpunkt {
    display: inline-block;
    text-align: center;

    > p {
      font-size: 12px;
    }
  }
}
