@import "include.scss";

#team {
  @include background-yellow-tiles();

  padding: 5% 0;
  position: relative;
  z-index: 50;

  > .team-heading {
    display: block;
    text-align: center;

    > span {
      display: block;
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    > img {
      border: 3px solid $c-h-pri;
      border-radius: 20px;
      max-width: 656px;
      width: calc(80% - 6px);
    }
  }

  .team-members {
    display: block;
    margin: 0 auto;
    text-align: center;
    width: 80%;
  }
}

.team-member {
  background-repeat: no-repeat;
  background-size: cover;
  border: 3px solid $c-h-pri;
  border-radius: 20px;
  box-shadow: 0 0 5px 2px rgba(50, 50, 50, 0.3);
  display: block;
  margin: 5% auto;
  position: relative;
  width: calc(100% - 6px);
  max-width: 300px;
  height: 400px;

  &:hover {
    cursor: pointer;
  }

  &.active {
    background-color: $c-white;
    background-image: none !important;

    > .team-member-title {
      display: none;
    }

    > .team-member-body {
      opacity: 1;
    }
  }

  > .team-member-title {
    bottom: 0;
    font-size: 19px;
    font-weight: bold;
    position: absolute;
    left: 0;
    width: 100%;
  }

  > .team-member-body {
    color: $c-black;
    opacity: 0;
    padding: 8% 5%;

    > .team-member-name,
    > .team-member-title {
      color: $c-black;
      font-family: "Indie Flower", cursive;
    }

    > .team-member-name {
      margin-top: 0;
    }

    > .team-member-title {
      margin-bottom: 0;
    }

    > .team-member-description {
      p {
        color: $c-black;
        font-family: "Indie Flower", cursive;
      }
    }
  }

  > .help {
    font-size: 19px;
    left: 0;
    padding: 5px 10px;
    position: absolute;
    text-align: center;
    top: 0;
    width: calc(100% - 20px);
    z-index: 99;
  }

  &.active > .help {
    display: none;
  }

  @media (min-width: $media-medium) {
    display: inline-block;
    margin: 25px;
    vertical-align: top;
  }
}
