/* Contains global constants */

// Colors

$c-pri: #ffcd00; // Yellow
$c-sec: #ff9f0d; // Orange
$c-h-pri: #3b4447; // Black (Highlight color)
$c-h-sec: #0007b2; // Blue (Highlight color)
$c-white: #fff;
$c-black: #071013;
$c-grey: #414549;
$c-grey-light: #9da6b0;

// Media query break points

$media-small: 576px;
$media-medium: 992px;
$media-large: 1200px;

$retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

// Mixins

@mixin logo($height, $paw-color: $c-sec, $text-color-pri: $c-sec, $text-color-sec: $c-white) {
  $small-height: $height * 0.2;
  $big-height: $height * 0.4;

  display: inline-block;
  height: $height;

  > svg {
    margin: $height * 0.1;
    height: $height * 0.8;
    width: $height * 0.8;

    transition: transform 0.2s;
  }

  > svg path {
    fill: $paw-color !important;
  }

  > .logo-title {
    display: inline-block;
    font-family: "Varela Round", sans-serif;
    font-weight: bold;
    height: $height * 0.8;
    padding: $height * 0.1 0 $height * 0.1 calc(#{$height} * 0.1);
    text-align: left;
    vertical-align: top;
    width: calc(100% - calc(#{$height} * 1.1));

    > span {
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    > span {
      color: $text-color-pri;
      font-size: $small-height;
      font-weight: bolder;
      height: $small-height;
      line-height: $small-height;
    }

    > span:first-child {
      color: $text-color-sec;
      font-size: $big-height * 0.8;
      height: $big-height;
      line-height: $big-height;
    }

    > span:nth-child(3) {
      color: $c-black;
      height: $small-height * 0.8;
      margin-top: $small-height * 0.2;
      vertical-align: top;

      > span {
        display: inline-block;
        font-size: $small-height * 0.8;
        font-weight: normal;
        height: $small-height * 0.8;
        line-height: $small-height;
        vertical-align: top;
      }

      > svg {
        height: $small-height * 0.8;
        margin-right: 4px;
        width: $small-height * 0.8;
      }
    }
  }

  &:hover {
    > svg {
      transform: scale(1.1);
    }
  }
}

@mixin menu-icon($size, $color: #000, $radius: 0) {
  display: inline-block;
  height: $size;
  vertical-align: top;
  width: $size;

  > span {
    background-color: $color;
    border-radius: $radius;
    display: block;
    height: $size / 5;
    margin: $size / 10 0;
    width: $size;
  }

  &:hover {
    cursor: pointer;
  }
}

@mixin text-outline($size, $color) {
  text-shadow: -$size 0 $color, 0 $size $color, $size 0 $color, 0 (-$size) $color;
}

@mixin background-yellow-tiles() {
  background-color: $c-pri;
  background-attachment: fixed;
  background-position: calc(50% - 20.5px);
  background-repeat: repeat;
}
