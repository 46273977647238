@import "include.scss";

#gallery {
  padding: 5%;

  .heading {
    text-align: center;
  }

  .tools {
    text-align: right;

    button {
      background-color: transparent;
      border: none;
      display: inline-block;

      &:hover {
        cursor: pointer;
      }

      > svg {
        height: 30px;
        transition: all 0.3s;
        width: 30px;
      }
    }

    .zoom-in:active > svg {
      transform: scale(1.1);
    }

    .zoom-out:active > svg {
      transform: scale(0.9);
    }

    .reshuffle:active > svg {
      transform: rotateZ(180deg);
    }
  }

  .image-box {
    display: inline-block;

    > .image-box-set {
      display: inline-block;
      margin: 0 3px;
      vertical-align: top;
      width: calc(100% / 4 - 6px);

      &:first-child > .image-box-item:first-child {
        border-top-left-radius: 30px;

        > img {
          border-top-left-radius: 30px;
        }
      }

      &:last-child > .image-box-item:first-child {
        border-top-right-radius: 30px;

        > img {
          border-top-right-radius: 30px;
        }
      }

      > .image-box-item {
        border-radius: 10px;
        box-shadow: 6px 6px 6px 2px rgba(50, 50, 50, 0.3);
        display: block;
        margin: 6px 0;
        transition: all 0.5s;

        &:hover,
        > img:hover {
          cursor: pointer;
        }

        &.active {
          border-radius: 10px;
          transform: scale(1.5);

          > img {
            border-radius: 10px;
          }
        }

        > img {
          height: 100%;
          vertical-align: top;
          width: 100%;
        }
      }
    }
  }
}
